import { IStackTokens, mergeStyleSets, Stack, Text } from '@fluentui/react';
import '../css/calculator.css';
import '../css/contact.css';

const classNames = mergeStyleSets({
    contactWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '20px',
        padding: '15px',
        maxWidth: '95%',
        boxSizing: 'border-box',
    },
    link: {
        width: 'auto',
        maxWidth: '100%',
        background: 'rgba(6, 128, 0, 0.8)',
        borderRadius: '12px',
        padding: '20px 50px',
        textAlign: 'center',
        color: '#FFFFFF',
        textDecoration: 'none',
        transition: 'all 120ms',
        selectors: {
            ':hover': {
                background: 'rgba(6, 128, 0, 1)',
            }
        }
    }
});

const verticalGapStackTokens: IStackTokens = {
    childrenGap: 20,
};

export const Contact: React.FunctionComponent = () => {
    return (
        <div className={classNames.contactWrapper}>
            <Stack className="calculatorStack" tokens={verticalGapStackTokens} style={{textAlign: 'center'}}>
                <Text variant="mediumPlus">Zadzwoń i ustal szczegóły:</Text>
                <a href="tel:726011188" className={classNames.link}>
                    {/* <Text variant="medium" style={{color: '#FFFFFF'}}>Zadzwoń!</Text><br /> */}
                    <Text className="linkText">726 011 188</Text>
                </a>
            </Stack>
        </div>
    );
}