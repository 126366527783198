import React, { useEffect } from 'react';
import './App.css';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { Homepage } from './scenes/Homepage';

ReactGA.initialize('UA-225439626-1');

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
});

export const App: React.FunctionComponent = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Homepage />
  );
};
