import { IStackStyles, IStackTokens, Stack } from '@fluentui/react';
import CookieConsent from 'react-cookie-consent';
import { Calculator } from '../components/Calculator';
import { Contact } from '../components/Contact';
import { Logo } from '../components/Logo';

const stackTokens: IStackTokens = { 
    childrenGap: 50 
};

const stackStyles: Partial<IStackStyles> = {
root: {
    width: '100%',
    maxWidth: '1000px',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
},
};

export const Homepage: React.FunctionComponent = () => {
    return (
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill styles={stackStyles} tokens={stackTokens}>
            <Calculator />
            
            <Contact />

            <Logo />

            <CookieConsent
                location="bottom"
                buttonText="Rozumiem"
                style={{ background: "rgba(0, 0, 0, 0.7)" }}
                buttonStyle={{ color: "#000000", fontSize: "13px" }}
            >
                Ta strona korzysta z plików cookie.
            </CookieConsent>
        </Stack>
    );
}