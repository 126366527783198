import { ChoiceGroup, CompoundButton, IChoiceGroupOption, IStackTokens, Stack, Text, MessageBar, MessageBarType, MaskedTextField, FontWeights, ITheme, memoizeFunction, ITextFieldProps, getTheme } from '@fluentui/react';
import { FormEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../css/calculator.css';

const typeOfServiceOptions: IChoiceGroupOption[] = [
    { key: 'mowing', text: 'Koszenie trawy', id: 'typeOfService'  },
    { key: 'scarifying', text: 'Wertykulacja', id: 'typeOfService' },
];

const grassHeightOptions: IChoiceGroupOption[] = [
    { key: 'small', text: 'Niska (< 15cm)', id: 'grassHeight' },
    { key: 'medium', text: 'Średnia (15-50cm)', id: 'grassHeight' },
    { key: 'large', text: 'Wysoka (> 50cm)', id: 'grassHeight' },
];

const terrainDifficultyOptions: IChoiceGroupOption[] = [
    { key: 'little', text: 'Mała', id: 'terrainDifficulty' },
    { key: 'medium', text: 'Średnia', id: 'terrainDifficulty' },
    { key: 'great', text: 'Duża', id: 'terrainDifficulty' },
];

const verticalGapStackTokens: IStackTokens = {
    childrenGap: 20,
};

const pricesConstantValues: any = {
    mowing: 0.4, // PLN per meter²
    scarifying: 0.8, // PLN per meter²
};

const constantValues: any = {
    averageDieselConsumption: 8, // L / 100km
    carDepreciationConstant: 0.45, // PLN / km
    dieselPrice: 7, // PLN
    distanceForFree: 5, // km
    baseMultiplier: 0.1, // 10%
    vat: 8, // %
};

const maskFormat: { [key: string]: RegExp } = {
    '*': /[0-9]/,
};

const getDescriptionStyles = memoizeFunction((theme: ITheme) => ({
    root: { color: theme.palette.green, fontWeight: FontWeights.bold },
}));

export const Calculator: React.FunctionComponent = () => {
    const [grassHeight, setGrassHeight] = useState<string | null>(null);
    const [terrainDifficulty, setTerrainDifficulty] = useState<string | null>(null);
    const [typeOfService, setTypeOfService] = useState<string | null>('mowing');
    const [surface, setSurface] = useState<number>(0);
    const [distance, setDistance] = useState<number>(0);
    const [discount, setDiscount] = useState<number>(0);
    const [isAllSet, setIsAllSet] = useState<boolean>(false);
    const [calculatedPrice, setCalculatedPrice] = useState<string | null>(null);
    const [calculatedPriceWithDiscount, setCalculatedPriceWithDiscount] = useState<string | null>(null);
    const [searchParams] = useSearchParams();
    const [isAdmin] = useState<string | null>(searchParams.get("manager"));

    useEffect(() => {
        if(typeOfService !== null && (typeOfService !== 'mowing' || grassHeight !== null) && terrainDifficulty !== null && surface >= 0 && distance >= 0) {
            setIsAllSet(true);
        } else {
            setIsAllSet(false);
        }
    }, [typeOfService, grassHeight, terrainDifficulty, surface, distance]);

    const _onRenderDescription = (props: ITextFieldProps | undefined): JSX.Element => {
        const theme = getTheme();
        return (
            <Text variant="small" styles={getDescriptionStyles(theme)} style={{visibility: distance <= constantValues.distanceForFree ? 'visible' : 'hidden'}}>
                {!!props && !!props.description ? props.description : ''}
            </Text>
        );
    };

    const _onChoiceGroupChange = (ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined): void => {
        if(option && option.id === 'grassHeight') {
            setGrassHeight(option.key);
        } else if(option && option.id === 'terrainDifficulty') {
            setTerrainDifficulty(option.key);
        } else if(option && option.id === 'typeOfService') {
            setTypeOfService(option.key);
        }
    };
    
    const _onInputChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined): void => {
        const eventTarget = event.target as HTMLInputElement;
        if(eventTarget && eventTarget.id && typeof newValue === 'string') {
            if(eventTarget.id === 'surface') {
                setSurface(newValue.length > 0 ? parseFloat(newValue) : 0);
            } else if(eventTarget.id === 'distance') {
                setDistance(newValue.length > 0 ? parseFloat(newValue) : 0);
            } else if(eventTarget.id === 'discount') {
                setDiscount(newValue.length > 0 ? parseFloat(newValue) : 0);
            }
        }
    };
    
    const _calculatePrice = () => {
        let grassHeightMultiplier: number = 1;
        let terrainDifficultyMultiplier: number = 1;

        switch(grassHeight) {
            case 'small':
                grassHeightMultiplier = 0;
                break;
            case 'medium':
                grassHeightMultiplier = 1 * constantValues.baseMultiplier;
                break;
            case 'large':
                grassHeightMultiplier = 2 * constantValues.baseMultiplier;
                break;
        }

        switch(terrainDifficulty) {
            case 'little':
                terrainDifficultyMultiplier = 0;
                break;
            case 'medium':
                terrainDifficultyMultiplier = 1 * constantValues.baseMultiplier;
                break;
            case 'great':
                terrainDifficultyMultiplier = 2 * constantValues.baseMultiplier;
                break;
        }

        let result = 0;
        if(typeOfService === 'mowing') {
            result = (pricesConstantValues.mowing * surface) * (1 + (terrainDifficultyMultiplier + grassHeightMultiplier));
        } else if(typeOfService === 'scarifying') {
            result = (pricesConstantValues.scarifying * surface) * (1 + terrainDifficultyMultiplier);
        }

        if(distance > constantValues.distanceForFree) {
            result += distance * ((constantValues.averageDieselConsumption * constantValues.dieselPrice) / 100); // fuel cost 
            result += distance * constantValues.carDepreciationConstant; // car deprecation, service etc.
            if(distance > 10) {
                result += (distance / 10) * 10; // extra 1 PLN per each km if more than 10km (for spending time)
            }
        }
        result += result * (constantValues.vat / 100);

        setCalculatedPrice(result.toFixed(2));
        if((isAdmin !== null && typeof isAdmin === 'string') && discount > 0) {
            setCalculatedPriceWithDiscount((result - (result * (discount / 100))).toFixed(2));
        }
    };

    return (
        <div className="calculatorWrapper">
            <Stack className="calculatorStack" tokens={verticalGapStackTokens}>
                <MessageBar
                    messageBarType={MessageBarType.info}
                    isMultiline={true}
                >
                    Na usługi wystawiamy fakturę VAT 8%.
                    <br /><br />
                    W przypadku potrzeby wykonania usług łączonych, nietypowych lub chęci negocjacji cen dla niestandardowych zadań, zachęcamy do bezpośredniego kontaktu - <strong>726 011 188</strong>.
                </MessageBar>

                <ChoiceGroup options={typeOfServiceOptions} onChange={_onChoiceGroupChange} defaultSelectedKey={typeOfService as string} label="Rodzaj usługi:" required={true} />
                { typeOfService === 'mowing' && 
                    <ChoiceGroup options={grassHeightOptions} onChange={_onChoiceGroupChange} label="Wysokość trawy:" required={true} />
                }
                <ChoiceGroup options={terrainDifficultyOptions} onChange={_onChoiceGroupChange} label="Trudność terenu:" required={true} />
                <Text variant="small" style={{marginTop: '5px'}}>Obiektywna ocena, w której bierzemy pod uwagę miedzy innymi:<br />
                    - ukształtowanie terenu (płaskie, górzyste, skarpy, rowy itd.)<br />
                    - elementy na terenie utrudniające pracę (krzaki, kretowiska, słupy itp.)</Text>

                <MaskedTextField id="surface" label="Powierzchnia terenu:" suffix='m²' onChange={_onInputChange} 
                            placeholder='0' required={true} mask="******" maskFormat={maskFormat} maskChar="" />
                <MaskedTextField id="distance" label="Odległość miejsca wykonania usługi od Wrześni:" suffix='km' onChange={_onInputChange}
                            placeholder='0' required={true} mask="***" maskFormat={maskFormat} maskChar=""
                            description={`Do ${constantValues.distanceForFree}km dojazd GRATIS!`}
                            onRenderDescription={_onRenderDescription} />

                { (isAdmin !== null && typeof isAdmin === 'string') &&
                    <MaskedTextField id="discount" label="Rabat:" suffix='%' onChange={_onInputChange} 
                        placeholder='0' required={false} mask="**" maskFormat={maskFormat} maskChar="" />
                }

                <CompoundButton 
                    primary secondaryText="Przybliżony koszt usługi" disabled={!isAllSet} checked={!!calculatedPrice}
                    style={{margin: '40px auto 0', border: '1px solid black'}} onClick={_calculatePrice}
                >
                    Oblicz
                </CompoundButton>

                {!!calculatedPrice && <>
                    <div style={{marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', marginTop: '40px'}}>
                        <Text variant="large">Orientacyjny koszt wykonania usługi: </Text>
                        <br />
                        <Text variant="xxLarge" style={{textDecoration: !!calculatedPriceWithDiscount ? 'line-through' : 'none'}}>
                            {calculatedPrice} PLN
                        </Text>
                        <Text variant="mediumPlus"> brutto</Text>
                        { !!calculatedPriceWithDiscount && 
                            <>
                                <br />
                                <br />
                                <Text variant="large">Po rabacie: </Text>
                                <br />
                                <Text variant="xxLarge">{calculatedPriceWithDiscount} PLN</Text>
                                <Text variant="mediumPlus"> brutto</Text>
                            </>
                        }
                    </div>
                
                    <MessageBar
                        messageBarType={MessageBarType.warning}
                        isMultiline={true}
                    >
                        Na cenę składa się między innymi:
                        <ul>
                            { typeOfService === 'scarifying' && 
                                <li>Grabienie oraz wywóz pozostałości po wertykulacji</li> 
                            }
                            <li>Koszt paliwa oraz amortyzacji sprzętu</li>
                            <li>Wynagrodzenia pracowników</li>
                            { distance > constantValues.distanceForFree && 
                                <li>Koszt dojazdu i transportu sprzętu do miejsca wykonania usługi</li> 
                            }
                            <li>Koszt utrzymania działalności</li>
                            <li>Podatek VAT 8%</li>
                        </ul>
                    </MessageBar>
                </>}
            </Stack>
        </div>
    );
};