import background from './images/background.jpg';
import { App } from './App';
import { mergeStyles } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import reportWebVitals from './reportWebVitals';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

initializeIcons();

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
  ':global(body)': {
    boxSizing: 'border-box',
    background: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: 'cover',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '20px 0',
  }
});

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
