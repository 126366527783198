import logo from '../images/kroptech-logo-text.png';
import { mergeStyleSets } from '@fluentui/react';

const classNames = mergeStyleSets({
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '20px',
        padding: '15px',
        maxWidth: '50%',
        boxSizing: 'border-box',
        marginBottom: '80px',
    },
    logo: {
        pointerEvents: 'none',
        width: 'auto',
        maxWidth: '100%',
    }
});

export const Logo: React.FunctionComponent = () => {
    return (
        <div className={classNames.logoWrapper}>
            <img className={classNames.logo} src={logo} alt="KropTech logo" />
        </div>
    );
}